import './App.css';

function App() {
  return (
    <>
      <div className="fancy-navbar">
        <div className="nb-toggle">
          <i className="fas fa-bars" />
        </div>
        <div className="nb">
          <a href="#services" className="fancy-btn">
            <i className="fa-solid fa-list-check" />
            <b>Skills</b>
          </a>
          <a href="#projects" className="fancy-btn">
            <i className="fa-solid fa-code" />
            <b>Projects</b>
          </a>
          <a href="#contact" className="fancy-btn">
            <i className="fa-solid fa-envelope" />
            <b>Contact</b>
          </a>
        </div>
        <br />
        <div className="jumbotron">
          <div className="text-wrapper">
            <img alt='launch' className="jmb-img" src="assets/images/wbb.png" />
            <h1 className="display-4 scale-in-center">Hey, I'm Alpay</h1>
            <p className="lead scale-in-center-delayed">
              {" "}
              I'm a software developer with experience in front and backend
              development, deep learning mostly with computer vision, and mobile
              apps. Currently building and deploying my own projects from scratch
              and also taking freelance orders. I have a keen interest in AI,
              especially AI in the field of computer vision.
            </p>
            <p className="sub">
              Have an inquiry?
            </p>
            <p className="lead">
              <a href="#contact" className="fancy-btn-action">
                <b>Get in touch</b>
              </a>
            </p>
          </div>
          <br />
        </div>
      </div>
      <div id="services">
        <h1 id="services-title"> Skills </h1>
        <div className="service-card">
          <div className="title">
            <h1> Web Development </h1>
            <h3>
              {" "}
              Building both, large and small-scale web projects, APIs, responsive
              web design &amp; deployment
            </h3>
          </div>
          <div className="content">
            <p></p>
            <ul>
              <li className="skill-item">Ruby on Rails</li>
              <li className="skill-item">React</li>
              <li className="skill-item">Python / Django</li>
              <li className="skill-item">Node.js</li>
              <li className="skill-item">CSS / SCSS</li>
            </ul>
            <p />
          </div>
        </div>
        <div className="service-card">
          <div className="title">
            <h1> Mobile App Development </h1>
            <h3> Developing dynamic mobile apps with responsive design </h3>
          </div>
          <div className="content">
            <p></p>
            <ul>
              <li className="skill-item">React-Native</li>
              <li className="skill-item">Expo</li>
              <li className="skill-item">Firebase</li>
            </ul>
            <p />
          </div>
        </div>
        <div className="service-card">
          <div className="title">
            <h1> DBMS </h1>
            <h3> Relational / NoSQL database experience &amp; ORMs/ODMs. </h3>
          </div>
          <div className="content">
            <p></p>
            <ul>
              <li className="skill-item">PostgreSQL / MySQL / SQLite</li>
              <li className="skill-item">MongoDB</li>
              <li className="skill-item">Django ORM / ActiveRecord / Mongoose / RN-Sqlite-Storage</li>
            </ul>
            <p />
          </div>
        </div>
        <div className="service-card">
          <div className="title">
            <h1> Deep Learning </h1>
            <h3>
              {" "}
              Object detection, classification and segmentation in images, defect
              detection, predictive analysis{" "}
            </h3>
          </div>
          <div className="content">
            <p></p>
            <ul>
              <li className="skill-item">Computer Vision</li>
              <li className="skill-item">PyTorch / Pytorch Lightning</li>
              <li className="skill-item">TensorFlow</li>
              <li className="skill-item">OpenCV</li>
            </ul>
            <p />
          </div>
        </div>
      </div>
      <div className="projects" id="projects">
        <h1>Projects</h1>
        <div className="projects-card">
          <div className="projects-img">
            <a href="https://ghost-status.com">
              <img src="assets/images/gs.png  " />
            </a>
          </div>
          <hr />
          <div className="projects-content">
            <p>
              {" "}
              Platform for website/API uptime checks, health analytics, visual ping,
              etc. Built using Rails, Python &amp; Vanilla JS. <br></br><span style={{color: 'orange'}}>[STILL BUILDING]</span>
            </p>
          </div>
        </div>
        <div className="projects-card-mobile">
          <div className="projects-img-mobile">
            <a href='https://liftsmart.app'>
              <img alt='LS app' src="assets/images/ls.png" />
            </a>
          </div>
          <hr />
          <div className="projects-content-mobile">
            <p>
              {" "}
              Mobile app for workouts and meal management - planned workouts &amp;
              meals, calorie counting, goal setting &amp; progress charts,
              recommendations, etc. The mobile application was built using
              React-Native and the API - Rails{" "}<br></br><span style={{color: 'green'}}>[LIVE]</span>
            </p>
          </div>
        </div>

        <div className="projects-card-mobile">
          <div className="projects-img-mobile">
            <a href='https://play.google.com/store/apps/details?id=com.techvisionlabs.carbsbud'>
              <img alt='LS app' src="assets/images/cb.png" />
            </a>
          </div>
          <hr />
          <div className="projects-content-mobile">
            <p>
              {" "}
              Mobile app for counting carbs &amp; fiber intake,
              logging food &amp; medicine with implemented reminders
              for them, etc. The mobile application was built using
              React-Native and Firebase{" "}<br></br><span style={{color: 'green'}}>[LIVE]</span>
            </p>
          </div>
        </div>

        <div className="projects-card-mobile">
          <div className="projects-img-mobile">
            <a href='/'>
              <img alt='FS app' src="assets/images/fs.png" />
            </a>
          </div>
          <hr />
          <div className="projects-content-mobile">
            <p>
              {" "}
              Mobile app to track fasting, fasting related information &amp; water consumption.
              The mobile application was built using
              React-Native and Firebase{" "}<br></br><span style={{color: 'orange'}}>[STILL BUILDING]</span>
            </p>
          </div>
        </div>

        <div className="projects-card">
          <div className="projects-img">
            <a href="http://omegasolar.nl">
              <img src="assets/images/os.png" />
            </a>
          </div>
          <hr />
          <div className="projects-content">
            <p>
              {" "}
              Re-developed website for the Dutch branch of the solar company
              OmegaSolar. Built using Rails and vanilla JS. <br></br><span style={{color: 'red'}}>[DISCONTINUED]</span>
            </p>
          </div>
        </div>


      </div>
      <hr className="fancy-separator" />
      <div className="contact" id="contact">
        <h1> Contact </h1>
        <br />
        <a href ='mailto:alpay.vodenicharov@gmail.com'><h2>Email me!</h2></a>
      </div>
    </>

  );
}

export default App;
